import React from 'react';
import { styled } from '@glitz/react';
import { Main, Basic, Layout, Appearance } from 'Shared/PageLayout';
import { pixelsToUnit } from 'Shared/Style';

type PropType = {
  children: React.ReactNode;
};

export default (props: PropType) => (
  <CustomContainer appearance={Appearance.Narrow}>
    <Basic layout={Layout.OneToOne}>{props.children}</Basic>
  </CustomContainer>
);

const CustomContainer = styled(Main, {
  paddingBottom: pixelsToUnit(100),
  maxWidth: pixelsToUnit(1057),
});
