import React from 'react';
import { styled } from '@glitz/react';
import { addUserLog, translate, replaceState, postJson } from '@avensia/scope';
import LoginModelType from './LoginModel.type';
import MainContainer from 'Shared/MainContainer';
import Input from 'Shared/Fields/Text';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import { pixelsToUnit } from 'Shared/Style';
import AccountPanel from './../Panel/AccountPanel';
import AccountForm from './../Form/AccountForm';
import { login as gtmLogin } from '../../TrackingInformation';
import { ErrorMessage } from '../../Shared/FeedbackMessage';
import { Checkbox } from 'Shared/Fields/Toggle';

type RequiredProps = {
  loginTitle: string;
  usernameLabel: string;
  usernamePlaceholder: string;
  passwordLabel: string;
  passwordPlaceholder: string;
  forgotPasswordLabel: string;
  forgotPasswordRedirectPage: string;
  loginButtonCaption: string;
  loginRedirectPage: string;
  rememberMeLabel: string;
  registrationTitle: string;
  registerRedirectPage: string;
  registrationButtonCaption: string;
};

type StateType = {
  username?: string;
  password?: string;
  rememberMe?: boolean;
  isFormValid: boolean;
  errorMessage: string;
};

type ConnectedPropType = RequiredProps & ConnectPropType;

const LOGIN_AJAXURL = '/dologin';
const ADD_USERLOG_SUCCESS = 'Login successful';
const ADD_USERLOG_FAILED = 'Login failed';
const GTM_LOGIN = 'Username/Password';

export default connectWithFeedback()(
  class LoginPage extends React.Component<ConnectedPropType, StateType> {
    constructor(props: ConnectedPropType) {
      super(props);
      this.state = {
        username: '',
        password: '',
        rememberMe: false,
        isFormValid: true,
        errorMessage: '',
      };
    }

    resetForm = (inputName: string) => {
      return this.setState({
        isFormValid: true,
        errorMessage: '',
      });
    };

    toggleRememberMe = () => {
      return this.setState({ rememberMe: !this.state.rememberMe });
    };

    setInputValues = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.name === 'rememberMe') {
        return this.setState({ rememberMe: !this.state.rememberMe });
      } else {
        this.resetForm(e.currentTarget.name);
        return this.setState({ ...this.state, [e.currentTarget.name]: e.currentTarget.value });
      }
    };

    validateInput = (data: LoginModelType) => {
      if (data.username === '' || data.password === '') {
        this.setState({ errorMessage: 'Invalid e-mail or password', isFormValid: false });
        return false;
      }

      return true;
    };

    onSubmit = async () => {
      const username = this.state.username.trim();
      const password = this.state.password.trim();
      const rememberMe = this.state.rememberMe;

      const query: LoginModelType = { username, password, rememberMe };
      const isValid = this.validateInput(query);

      if (isValid) {
        try {
          const { success, message } = await postJson(LOGIN_AJAXURL, query);
          if (success) {
            addUserLog(ADD_USERLOG_SUCCESS);
            gtmLogin(GTM_LOGIN);

            if (this.props.loginRedirectPage) {
              return replaceState(this.props.loginRedirectPage, {
                includeAppShellData: true,
              });
            } else {
              return replaceState(null, { includeAppShellData: true });
            }
          } else {
            addUserLog(ADD_USERLOG_FAILED);
            this.setState({
              isFormValid: false,
              errorMessage: message,
            });
            return Promise.reject(null);
          }
        } catch (error) {
          this.setState({ errorMessage: !!error ? error : translate('/Account/Login/ServerError') });
          return Promise.reject(null);
        }
      } else {
        return Promise.reject(null);
      }
    };

    render() {
      const { errorMessage, isFormValid } = this.state;

      return (
        <MainContainer>
          <AccountForm
            heading={this.props.loginTitle}
            buttonCaption={this.props.loginButtonCaption}
            redirectLink={this.props.forgotPasswordRedirectPage}
            redirectLabel={this.props.forgotPasswordLabel}
            buttonAction={this.onSubmit}
          >
            <InputContainer>
              <InputLabel>{this.props.usernameLabel}</InputLabel>
              <InputField
                onChange={this.setInputValues}
                required
                type="username"
                name="username"
                placeholder={this.props.usernamePlaceholder}
                invalid={!isFormValid}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>{this.props.passwordLabel}</InputLabel>
              <InputField
                onChange={this.setInputValues}
                required
                type="password"
                name="password"
                placeholder={this.props.passwordPlaceholder}
                invalid={!isFormValid}
              />
              {!!errorMessage && <Error>{translate('/Account/Login/InvalidCredentials')}</Error>}
            </InputContainer>
            <Checkbox
              css={{ marginBottom: pixelsToUnit(26) }}
              onChange={this.toggleRememberMe}
              checked={this.state.rememberMe}
            >
              <RememberMeLabel>{this.props.rememberMeLabel}</RememberMeLabel>
            </Checkbox>
          </AccountForm>
          <AccountPanel
            heading={this.props.registrationTitle}
            buttonCaption={this.props.registrationButtonCaption}
            redirectLink={this.props.registerRedirectPage}
          />
        </MainContainer>
      );
    }
  },
);

const InputLabel = styled.label({
  marginLeft: pixelsToUnit(16),
});

const InputField = styled(Input, {
  paddingLeft: pixelsToUnit(16),
  borderRadius: '0.15rem'
});

const InputContainer = styled.div({
  minHeight: pixelsToUnit(112),
});

const Error = styled(ErrorMessage, {
  marginTop: pixelsToUnit(5),
  paddingLeft: pixelsToUnit(16),
});

const RememberMeLabel = styled.span({
  marginLeft: pixelsToUnit(11),
});
