/**
 * @ComponentFor LoginBlock
 */
import React from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Login from './Login';
import LoginPropType from './LoginBlock.type';

type PropType = LoginPropType;

const LoginBlock = (props: PropType) => (
  <Login
    loginTitle={epiPropertyValue(props.loginTitle)}
    usernameLabel={epiPropertyValue(props.usernameLabel)}
    usernamePlaceholder={epiPropertyValue(props.usernamePlaceholder)}
    passwordLabel={epiPropertyValue(props.passwordLabel)}
    passwordPlaceholder={epiPropertyValue(props.passwordPlaceholder)}
    forgotPasswordLabel={epiPropertyValue(props.forgotPasswordLabel)}
    forgotPasswordRedirectPage={epiPropertyValue(props.forgotPasswordRedirectPage).url}
    loginButtonCaption={epiPropertyValue(props.loginButtonCaption)}
    loginRedirectPage={epiPropertyValue(props.loginRedirectPage).url}
    rememberMeLabel={epiPropertyValue(props.rememberMeLabel)}
    registrationTitle={epiPropertyValue(props.registrationTitle)}
    registerRedirectPage={epiPropertyValue(props.registerRedirectPage).url}
    registrationButtonCaption={epiPropertyValue(props.registrationButtonCaption)}
  />
);

export default LoginBlock;
